<template>
  <div id="content">
    <div class="uk-flex uk-flex-between">
      <h1 class="uk-text-left uk-margin-remove uk-width-2-5 uk-text-truncate"><span @click="navigateBack" style="text-decoration: none;"><span uk-icon="icon: chevron-left; ratio: 2"></span>
      <span v-if="fetching">{{ $t('Actions.Loading') }}</span>
      <span v-else>{{ folderName }}</span></span></h1>
      <div class="uk-flex-right uk-flex">
       <div v-if="contentNodes.length > 0 || contentFilter">
          <button :uk-tooltip="$t('Generic.Labels.Filter')" style="padding-left:10px; padding-right:10px; width:42px;" class="uk-button uk-button-primary uk-margin-small-right" type="button">
            <img src="@/assets/filter-icon.svg" alt="Filter" uk-svg />
          </button>
          <div class="uk-padding-small" uk-dropdown="mode: hover; pos: bottom-right; offset: 10; animation: uk-animation-slide-top-small; flip:true" style="min-width:120px">
            <ul class="uk-nav uk-dropdown-nav uk-text-left">
              <li :class="contentFilter === '' ? 'uk-active uk-text-bold' : ''"><a @click="setFilter('')"> {{$t('Generic.Labels.All')}} </a> </li>
              <li :class="contentFilter === '360Video' ? 'uk-active uk-text-bold' : ''"><a @click="setFilter('360Video')"> {{ $t('Pages.Content.360Video') }} </a> </li>
              <li :class="contentFilter === '2D' ? 'uk-active uk-text-bold' : ''"><a @click="setFilter('2D')"> {{ $t('Pages.Content.2DVideo') }} </a> </li>
              <li :class="contentFilter === type ? 'uk-active uk-text-bold' : ''" v-for="type in ContentType.values.filter( type => type !== 'VOD' )" :key="type" :value="type" >
                <a @click="setFilter(type)">{{ $t( `enums.ContentType.${type}` ) }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="uk-padding-remove" v-if="isMutable">
           <button style="padding-left:10px; padding-right:10px" :uk-tooltip="$t('Pages.Publishing.Languages')" aria-label="Languages" class="uk-button uk-button-primary uk-margin-small-right" type="button" @click="()=>toggle =!toggle">
              <i uk-icon="icon: world"></i> <i  v-if="!toggle" uk-icon="icon: triangle-down"></i>
              <i v-else uk-icon="icon: triangle-up"></i>
          </button>
          <button v-if="toggle" class="uk-button uk-button-primary new-collection-btn" href="#content-lang-modal" uk-toggle>
            <span uk-icon="plus-circle"></span> {{ $t('Languages.AddLanguage') }}
          </button>
          <button class="uk-button uk-button-primary new-collection-btn uk-margin-small-left" href="#new-content-collection-modal" uk-toggle>
            <span class="uk-margin-small-right" uk-icon="plus-circle"></span> {{ $t('Pages.Publishing.NewFolder') }}
          </button>
      </div>
      <div class="uk-padding-remove uk-margin-small-left" v-if="isMutable">
        <button class="uk-button uk-button-primary new-collection-btn">
          <span class="uk-margin-small-right" uk-icon="plus-circle"></span> {{ $t('Pages.Content.NewContent') }}
        </button>
        <div class="uk-padding-small" id="new-content-types" uk-dropdown="mode: hover; pos: bottom-right; offset: 10; animation: uk-animation-slide-top-small; flip:true">
            <ul class="uk-nav uk-dropdown-nav uk-text-left">
              <li v-if="featureSupport && featureSupport.ads" ><a @click="openAdPicker('ad')"> {{ $t('Pages.Content.ContentTypes.Ad') }} </a></li>
              <li><a @click="openMediaPicker('stream')"> {{ $t('Pages.Content.ContentTypes.LiveEvent') }} </a></li>
              <li><a @click="openMediaPicker('video')"> {{ $t('Pages.Content.ContentTypes.Video') }} </a></li>
              <li v-if="featureSupport && featureSupport.interactiveVideos"><a @click="openInteractivePicker('interactive')"> {{ $t('Pages.Content.ContentTypes.Interactive') }} </a></li>
              <li v-if="featureSupport && featureSupport.ar" ><a @click="openNewArModal()"> {{ $t('Pages.Content.ContentTypes.AR') }} </a></li>

            </ul>
        </div>
      </div>
      </div>
    </div>
    <hr />
    <PublishingDashboard :properties-data="content" uk-scrollspy="target: > div; cls: uk-animation-slide-top-medium; delay: 50; offset-top: 500;"/>
    <hr />
    <span v-if="toggle">
      <baseTable :tableHeaders="langTableHeaders" class="uk-margin-small-top">
        <tr
        v-for="(lang, i) in collectionLanguages"
        :key="i"
        class="uk-items-center table-row">
          <td><span>{{ langTitle( lang ) }}</span> <span v-if="lang.language==='en-US'" class="uk-text-meta">{{$t('Languages.Default')}}</span></td>
          <td class="uk-width-large uk-text-truncate"><span>{{ lang.name }}</span></td>
          <td class="uk-text-truncate uk-width-2xlarge uk-preserve-width">{{ lang.description }}</td>
          <td class="uk-text-center">
            <div uk-icon="icon: pencil; ratio: 1" class="trash-icon" @click.stop="clickEditLang( lang )" />
              <span v-if="deletingLangList.includes( lang )" class="trash-icon uk-margin-small-left" uk-spinner="ratio: 1" />
            <div v-else uk-icon="icon: trash; ratio: 1" class="uk-margin-small-left trash-icon" @click.stop="deleteLanguage( lang )" />
          </td>
        </tr>
      </baseTable>
      </span>
    <span v-if="!fetching && contentNodes.length === 0 && contentFilter!==''" class="uk-margin-top uk-text-meta">{{ $t('Pages.Content.NoContent') }}</span>
    <div class="uk-text-center uk-text-meta uk-margin-top" v-if="!fetching && contentNodes.length === 0 && contentFilter==='' && isMutable">
      <span class="uk-margin-small-left">{{ $t('Pages.Content.info') }}</span>
    </div>
    <span v-if="!fetching && contentNodes.length === 0 && contentFilter==='' && !isMutable" class="uk-margin-top uk-text-meta">{{ $t('Pages.Content.NoContentItems') }}</span>
    <div v-if="fetching && (collectionId !== currentCollectionId)" uk-spinner />
    <baseTable v-if="contentNodes && contentNodes.length> 0" :tableHeaders="tableHeaders" class="uk-margin-small-top">
        <DraggableItem
          v-for="contentItem in contentNodes"
          :key="contentItem.id"
          v-model="contentItem.id"
          tag="tr"
          tabindex="-1"
          :draggable="isMutable"
          @drop="drop( $event, contentItem.id )">
          <td v-if="isMutable" class="icon-drag">
            <div uk-icon="icon: chevron-up; ratio: 0.8" />
            <div uk-icon="icon: chevron-down; ratio: 0.8" />
          </td>
          <td v-if="isMutable"> <input type="checkbox" :uk-tooltip="isContentCollection(contentItem) ?  $t('Pages.Content.VisibilityCollectionTooltip') : $t('Pages.Content.VisibilityTooltip')"
            v-model="contentItem.checked" :disabled="isContentCollection(contentItem)"> </td>
          <RouterLink tag="td" :to="toContent( contentItem )" class="icon">
            <div v-if="contentItem.type=== 'AR'" uk-icon="icon" :uk-tooltip="getContentType(contentItem.type)">
              <img  style="width:28px" src="@/assets/ar2.svg" uk-svg /> 
            </div>
            <div v-else :uk-icon="getContentIcon(contentItem.type)" :uk-tooltip="getContentType(contentItem.type)"/>
          </RouterLink>
          <RouterLink tag="td" :to="toContent( contentItem )" class="icon-image">
            <ImageOrAbbrevation :src="getContentItemArtKey(contentItem)" :alt="contentTitle( contentItem )" width="85px" imageClass='uk-border-rounded'/>
          </RouterLink>
          <RouterLink class="name uk-text-break" tag="td" :to="toContent( contentItem )">{{ contentTitle(contentItem) }}</RouterLink>
          <RouterLink tag="td" :to="toContent( contentItem )" v-if="isContentCollection(contentItem)" class="uk-text-capitalize type uk-text-center">
            {{ $t('Pages.Collections.Types.'+ contentItem.ref.seasonType.charAt(0) + contentItem.ref.seasonType.toLowerCase().slice(1)) }}
          </RouterLink>
          <td v-else class="uk-text-center">
            <div :class="{ controls: checkAppRestrictions(contentItem) }" :uk-tooltip="$t('Pages.Content.AppRestrictions'+(checkAppRestrictions(contentItem)?'':'None'))" class="uk-margin-small-right" uk-icon="grid" />
            <div :class="{ controls: checkGeoRestrictions(contentItem) }" :uk-tooltip="$t(`Pages.Content.LocationRestrictions`+(checkGeoRestrictions(contentItem)?'':'None'))" class="uk-margin-small-right" uk-icon="location" />
            <div :class="{ controls: checkAvailabilityRestrictions(contentItem) }" :uk-tooltip="$t(`Pages.Content.TimeRestrictions`+(checkAvailabilityRestrictions(contentItem)?'':'None'))" class="uk-margin-small-right" uk-icon="calendar" />
          </td>
          <RouterLink tag="td" :to="toContent( contentItem )" class="access uk-text-center">
            <span class="uk-badge" :style="{background: getAccessColor(contentItem)}"></span>
            <span class="uk-text-capitalizex uk-margin-small-left">{{ $t('Pages.Content.Availability.' + contentItem.access.charAt(0) + contentItem.access.toLowerCase().slice(1) )}}</span>
          </RouterLink>
            <td v-if="isMutable" class="uk-text-center"> <button uk-icon="settings" :disabled="!contentItem.checked || checkAvailabilityRestrictions(contentItem)" :uk-tooltip="!isContentCollection(contentItem) ? contentItem.checked ? checkAvailabilityRestrictions(contentItem) ? $t('Pages.Content.VisibilityTooltipAvailabilityEnabled') : $t('Pages.Content.SetVisibility') : $t('Pages.Content.VisibilityDisableTooltip') : $t('Pages.Content.VisibilityCollectionTooltip')" uk-toggle="target: #content-visibility-modal" class="icon-control" /> </td>
           <td v-if="isContentCollection(contentItem) && isMutable" class="uk-text-right uk-flex uk-flex-row uk-flex-center uk-margin">
            <ToggleButton uk-tooltip="Staging" @toggle="setStaging( contentItem, $event )" :value="contentItem.ref.staging" />
          </td>
           <td v-if="!isContentCollection(contentItem) && isMutable" class="uk-text-right uk-flex uk-flex-row uk-flex-center uk-margin" :uk-tooltip="$t('Pages.Content.StagingToolTip')">
            <ToggleButton disabled />
          </td>
          <td v-if="!isContentCollection(contentItem) && isMutable" class="uk-text-center auto@m auto@l">
            <div uk-icon="copy" :uk-tooltip="$t('Actions.Copy')" @click="copyContent(contentItem)" uk-toggle="target: #move-modal" class="icon-control uk-margin-small-right" />
            <div uk-icon="move" :uk-tooltip="$t('Actions.Move')" @click="moveContent(contentItem)" uk-toggle="target: #move-modal" class="icon-control  uk-margin-small-right" />
            <span v-if="deletingList.includes( contentItem )" uk-spinner="ratio: 0.7"/>
            <div v-else uk-icon="icon: trash; ratio: 1" class="icon-control" :uk-tooltip="$t('Actions.Delete')" @click.stop="deleteContent(contentItem)" />
          </td>
          <td v-if="isContentCollection(contentItem) && isMutable" class="uk-text-center">
            <div uk-icon="icon: pencil; ratio: 1" uk-tooltip="Edit" class="uk-text-right uk-margin-small-right edit-icon" @click.stop="clickEdit( contentItem )" />
            <span v-if="deletingList.includes( contentItem )" uk-spinner="ratio: 0.7"/>
            <div v-else uk-icon="icon: trash; ratio: 1" class="icon-control" :uk-tooltip="$t('Actions.Delete')" @click.stop="deleteContent(contentItem)" />
          </td>
           
        </DraggableItem>
    </baseTable>
      <div id="content-visibility-modal" uk-modal="bg-close: false; esc-close: false">
        <div class="uk-modal-dialog">
            <div class="uk-modal-header">
              <h3 class="uk-margin-small-bottom uk-text-left"> {{ $t('Pages.Content.Availability.Visibility')}} </h3>
            </div>
            <div class="uk-modal-body uk-clearfix">
              <div class="uk-text-muted uk-margin-small uk-text-left">{{$t("Pages.Content.VisibilityNote")}}</div>
              <div class="uk-button-group uk-align-left" id="content-visibility">
                <button :class="{ 'selected': visibility === 'PRIVATE' }" class="uk-button uk-button-secondary access" @click="visibility='PRIVATE'">{{ $t( 'Pages.Content.Availability.Private' ) }}</button>
                <button :class="{ 'selected': visibility === 'TESTING' }" class="uk-button uk-button-secondary access" @click="visibility='TESTING'">
                  {{ $t( 'Pages.Content.Availability.Testing' ) }}
                </button>
                <button :class="{ 'selected': visibility === 'PUBLIC' }" class="uk-button uk-button-secondary access" @click="visibility='PUBLIC'">
                  {{ $t( 'Pages.Content.Availability.Public' ) }}
                </button>
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button class="uk-button uk-button-default uk-margin-small-right uk-modal-close" type="button" @click="cancelSetVisibility"> {{ $t('Actions.Cancel') }} </button>
              <button v-if="!saving" class="uk-button uk-button-primary uk-margin-remove-bottom" :disabled="!visibility" @click="setVisibility">  {{ $t('Actions.Save') }} </button>
              <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
            </div>
        </div>
      </div>
      <div id="info-content-modal" uk-modal="bg-close: false; esc-close: false">
        <div class="uk-modal-dialog uk-modal-body">
            <button class="uk-modal-close-default" type="button" uk-close></button>
              <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
            <p class="uk-text-meta">{{ $t('DeleteModal.CannotDeleteCollection') }}</p>
        </div>
      </div>
      <div id="info-language-modal" uk-modal="bg-close: false; esc-close: false">
      <div class="uk-modal-dialog uk-modal-body">
          <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
          <p class="uk-text-meta">{{ $t('DeleteModal.DefaultLanguageCannotBeDeleted') }}</p>
      </div>
    </div>
      <div id="move-modal" uk-modal="bg-close: false; esc-close: false">
        <div  class="uk-modal-dialog uk-padding uk-modal-body">
          <div class="uk-inline uk-width-xlarge uk-text-left uk-margin-bottom">
            <span> {{ copyingContent ? $t('Actions.Copy') : $t('Actions.Move') }} </span>
            <span class="uk-padding-small-left content-title uk-text-italic"> {{ copyingContent ? contentTitle(copyingContent) : contentTitle(movingContent) }} </span>
            <span class=" uk-padding-small-left"> {{ $t('Pages.Content.To') }}</span>
          </div>
        <form class="uk-search uk-search-default uk-width-xlarge">
          <div>
            <input class="uk-input uk-text-meta uk-text-light" type="text" v-model="selectedInput" @keyup="openDropdown" :placeholder="`${$t('Actions.Select')} ${$t('Pages.Content.ContentTypes.Folder')}`" uk-toggle="target:.select">
            <span class="uk-icon triangle-down" uk-icon="triangle-down" />
          </div>
          <div class="uk-overflow-auto uk-height-medium select uk-padding-remove" id="select" uk-dropdown="mode: click; pos: bottom-justify; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 100;flip: true">
            <span v-if="this.orgCollectionsLoading" uk-spinner class="uk-padding" style="color:#333!important" />
            <ul class="uk-list uk-dropdown-nav uk-list-striped" v-else>
              <li class="uk-text-left uk-text-truncate" v-for="folder in searchResults" :key="folder.value.id" style="cursor:pointer" :value="folder.value.id" @click="clickSelect(folder)">{{ folder.name }}</li>
            </ul>
          </div>
        </form>
        <div class= "uk-text-right uk-margin-top">
          <button class="uk-button uk-button-default uk-margin-small-right uk-modal-close" type="button" @click="cancel()"> {{ $t('Actions.Cancel') }} </button>
          <button class="uk-button uk-button-primary uk-margin-small-right" type="button" :disabled="!selectedFolder" @click="moveOrCopyContent()"> {{ movingContent ? $t('Actions.Move') : $t('Actions.Copy') }} </button>
        </div>
        </div>
      </div>
      <div id="new-content-collection-modal" container="#content" uk-modal="bg-close: false; esc-close: false; stack: true">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-left">{{ editCollection ? $t('Pages.Publishing.EditFolder') :  $t('Pages.Publishing.AddFolder')}}</h2>
        </div>
        <div class="uk-modal-body uk-flex uk-flex-column">
           <div class="uk-flex uk-flex-column uk-flex-middle">
            <ImgOrFallback class="uk-margin-bottom" :src="form.keyArtMedia ? form.keyArtMedia.file.url : icon"  height="160px" width="283px" imageClass='uk-border-rounded'/>
            <h5 class="uk-text-muted uk-margin-remove">{{$t('Generic.Labels.Image16x9')}}</h5>
            <button class="uk-button uk-button-primary  uk-width-auto uk-margin-small-top" @click="openMediaPicker()">{{ $t('Pages.Publishing.UploadKeyArt') }}</button>
          </div>
          <form id="new-folder" class="uk-form-stacked uk-text-left" @submit.prevent="onSubmit();">
              <button class="uk-modal-close-default" type="button" uk-close @click="resetForm()"></button>
              <div class="uk-margin">
                  <label class="uk-form-label">{{$t('Pages.Publishing.FolderName') }} <span class="uk-text-danger">*</span></label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input class="uk-input" type="text"                           
                          :class="{ 'invalid-border': editCollection ? !this.form.name || this.form.name.trim().length<3 || ( this.form.name && this.form.name.length<3 ) : form.nameError }"
                          v-model.trim="form.name"
                          autocomplete="off"
                          required
                          @input="validateName($event.target.value)"
                          :placeholder="$t('Pages.Publishing.Placeholders.FolderName')" />
                          <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="editCollection ? form.name.length < 3 : form.nameError" uk-icon="icon: warning" ></span>
                          <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="editCollection ? form.name.length < 3 : form.nameError" >{{ form.nameError || 'Name should be atleast 3 characters' }}</span>
                    </div>
                  </div>
              </div>
              <div class="uk-margin uk-margin-remove-bottom">
                <label class="uk-form-label">{{ $t('Pages.Publishing.Description') }}</label>
                <div class="uk-form-controls">
                  <textarea class="uk-textarea" 
                      rows="5"
                      type="text"
                      v-model.trim="form.description"
                      autocomplete="off"
                      :placeholder="$t('Pages.Publishing.Placeholders.Description')" />
                </div>
              </div>
              <div class="uk-margin uk-flex">
                <div class="uk-width-small">
                  <label class="uk-form-label">{{ $t('Pages.Collections.FolderType') }}</label>
                    <select class="uk-select" v-model="form.type">
                      <option value="SEASON">{{ $t('Pages.Collections.Types.Season')}}</option>
                      <option value="CUSTOM">{{ $t('Pages.Collections.Types.Custom')}}</option>
                      <option value="SERIES">{{ $t('Pages.Collections.Types.Series')}}</option>
                      <option value="YEAR">{{ $t('Pages.Collections.Types.Year')}}</option>
                    </select>
                </div>
              <div class="uk-margin-medium-left">
                <label class="uk-form-label">{{ $t('Pages.Collections.Staging') }}</label>
                <ToggleButton class="uk-margin-small-top" :value="form.staging" @toggle="form.staging=$event" />
              </div>
            </div>
          </form>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="resetForm()">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!isLoading" class="uk-button uk-button-primary" @click="onSubmit()">{{ $t('Actions.Save') }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
     <div id="content-lang-modal" container="#content" uk-modal="bg-close: false; esc-close: false; stack: true">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-left">{{ editLanguage ? $t('Languages.EditLanguage') : $t('Languages.AddLanguage') }}</h2>
        </div>
        <div class="uk-modal-body uk-flex uk-flex-column">
            <form id="new-language" class="uk-form-stacked uk-text-left" @submit.prevent="onLangCreate();">
              <button class="uk-modal-close-default" type="button" uk-close @click="resetLangForm()"></button>
              <div class="uk-margin">
                <span uk-grid >
                <label class="uk-form-label uk-width-1-2">{{$t('Languages.Language') }} <span class="uk-text-danger">*</span></label>
                <span v-if="langForm.languageError" class="uk-text-right uk-text-danger uk-width-1-2">{{$t('Generic.Errors.MandatoryField')}}<span class="uk-margin-small-left" uk-icon="icon: warning"></span></span>
                </span>
                <div uk-form-cutom="target: true" class="uk-form-custom" :class="{ 'invalid-border': langForm.languageError }">
                    <select class="uk-select uk-width-xlarge" @click="langForm.languageError = false" :class="{ 'uk-disabled': editLanguage }" id="content-lang" v-model="langForm.language">
                      <option
                        v-for="(lang, i) in editLanguage?SupportedLanguages:availableLanguages"
                        :key="i"
                        :value="lang"
                      >
                        {{ $t( `Languages.isoLanguageLabels.${lang}` ) }}
                      </option>
                    </select>
                     <span class="uk-select uk-width-xlarge uk-text-meta" :class="{ 'uk-disabled': editLanguage }" id="content-lang">{{ langForm.language ? $t( `Languages.isoLanguageLabels.${langForm.language}` ) : $t('Languages.SelectLanguage') }}</span>
                  </div>
              </div>
              <div class="uk-margin">
                  <label class="uk-form-label">{{$t('Generic.Labels.Title') }} <span class="uk-text-danger">*</span></label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input class="uk-input" type="text"
                          :class="{ 'invalid-border': editLanguage ? !langForm.title || langForm.titleError : langForm.titleError}"
                          v-model.trim="langForm.title"
                          autocomplete="off"
                          required
                          @input="validateTitle($event.target.value)"
                          :placeholder="$t('Generic.Labels.Title')" />
                          <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="editLanguage ? !langForm.title || langForm.titleError : langForm.titleError" uk-icon="icon: warning" ></span>
                          <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="editLanguage ? !langForm.title || langForm.titleError : langForm.titleError" >{{langForm.titleError || 'Title should be atleast 3 characters'}}</span>
                    </div>
                  </div>
              </div>
              <div class="uk-margin">
                  <label class="uk-form-label">{{ $t('Languages.Description') }}</label>
                  <div class="uk-form-controls">
                      <textarea class="uk-textarea" 
                      rows="2"
                      type="text"
                      v-model.trim="langForm.description"
                      autocomplete="off"
                      :placeholder="$t('Languages.Description')" />
                  </div>
              </div>
          </form>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="resetLangForm()">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!isLangLoading" class="uk-button uk-button-primary" @click="onLangCreate()">{{ $t('Actions.Save') }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
    <div v-if="showPicker" id="media-picker-modal" container="#content" uk-modal="bg-close: false; esc-close: false;">
      <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
        <ModalMediaPicker :button-text="buttonText" :media-class-filter="mediaFilter" @cancel="closeModal()" @select="selectedMedia( $event )" />
      </div>
  </div>
   <div v-if="showAdPicker" id="media-ad-picker-modal" container="#content" uk-modal="bg-close: false; esc-close: false;">
      <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
        <ModalAdPicker :button-text="buttonText" @cancel="closeAdModal()" @selectAd="selectedAd( $event )"/>
      </div>
  </div>
  <div v-if="showInteractivePicker" id="media-interactive-picker-modal" container="#content" uk-modal="bg-close: false; esc-close: false;">
    <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
        <ModalInteractivePicker :button-text="buttonText" @selectInteractive="selectedInteractive( $event )"/>
    </div>
  </div>
  <div id="new-interactive-content-details-modal" container="#content" uk-modal="bg-close: false; esc-close: false; stack: true">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-left">{{ $t('Pages.Content.CreateInteractive')}}</h2>
        </div>
        <div class="uk-modal-body uk-flex uk-flex-column">
          <form id="new-folder" class="uk-form-stacked uk-text-left" @submit.prevent="onSubmit();">
              <div class="uk-margin">
                  <label class="uk-form-label">{{$t('Pages.Content.Labels.Title') }} <span class="uk-text-danger">*</span></label>
                  <div class="uk-form-controls">
                      <div class="uk-inline uk-width-1-1">
                        <input class="uk-input" type="text" 
                          :class="{ 'invalid-border': interactive.title.length > 0 && isCreateIVDisabled }"
                          v-model.trim="interactive.title"
                          autocomplete="off"
                          required
                          :placeholder="$t('Pages.Content.Placeholders.Title')" />
                          <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="interactive.title.length > 0 && isCreateIVDisabled" uk-icon="icon: warning" ></span>
                          <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="interactive.title.length > 0 && isCreateIVDisabled" >{{$t('Pages.Content.TitleError')}}</span>
                      </div>
                  </div>
              </div>
              <div class="uk-margin uk-margin-remove-bottom">
                  <label class="uk-form-label">{{$t('Pages.Content.Labels.Headline') }} </label>
                  <div class="uk-form-controls">
                      <input class="uk-input" type="text" 
                        v-model.trim="interactive.headline"
                        autocomplete="off"
                        :placeholder="$t('Pages.Content.Placeholders.Headline')" />
                  </div>
              </div>
              <div class="uk-margin uk-margin-remove-bottom">
                <label class="uk-form-label">{{ $t('Pages.Publishing.Description') }}</label>
                <div class="uk-form-controls">
                  <textarea class="uk-textarea" 
                      rows="5"
                      type="text"
                      v-model.trim="interactive.description"
                      autocomplete="off"
                      :placeholder="$t('Pages.Content.Placeholders.Description')" />
                </div>
              </div>
              <div class="uk-margin uk-margin-remove-bottom">
                <label class="uk-form-label">{{ $t('Pages.Content.Labels.Tags') }}</label>
                <div class="uk-form-controls">
                  <input 
                    class="uk-input"
                    type="text"
                    v-model="tag"
                    autocomplete="off"
                    :placeholder="$t('Pages.Content.Placeholders.Tags')"
                    @keyup="checkIfEnter($event)" />
                </div>
                <div v-for="tag in interactive.tags" :key="tag" class="tag-item uk-margin-small-top uk-margin-small-right">
                  <div class="label uk-margin-small-left uk-margin-small-right" @click="$emit('click')">
                      <span :uk-tooltip="tag" :key="tag"> {{ tag }} </span>
                  </div>
                  <span class="close uk-margin-auto-vertical uk-margin-small-right" style="cursor: pointer;" uk-icon="icon: close;ratio: 0.8" @click="deleteTag(tag)"></span>
                </div>
              </div>
              <div class="uk-margin uk-flex">
                <div class="uk-width-small">
                  <label class="uk-form-label">{{ $t('Pages.Content.Labels.VideoCategoryType') }}</label>
                    <select class="uk-select" v-model="interactive.category">
                      <option v-for="category in EntertainmentCategoryList.slice().sort()" :key="category"
                        :value="category">{{ $t(`Pages.Content.VideoCategoryTypes.${category}`)}}</option>
                    </select>
                </div>
            </div>
          </form>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="resetIVForm()">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!isLoading" class="uk-button uk-button-primary" :disabled="isCreateIVDisabled" @click="createIVContent()">{{ $t('Pages.Content.CreateInteractiveContent') }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
    
    <div id="new-ar-modal" container="#content" uk-modal="bg-close: false; esc-close: false; stack: true">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-left">{{ $t('Pages.Content.CreateArContent')}}</h2>
        </div>
        <div class="uk-modal-body uk-flex uk-flex-column">
          <form id="new-folder" class="uk-form-stacked uk-text-left" @submit.prevent="onSubmit();">
              <div class="uk-margin">
                  <label class="uk-form-label">{{$t('Pages.Content.Labels.Title') }} <span class="uk-text-danger">*</span></label>
                  <div class="uk-form-controls">
                      <div class="uk-inline uk-width-1-1">
                        <input class="uk-input" type="text" 
                          :class="{ 'invalid-border': isCreateTitleError }"
                          v-model.trim="ar.title"
                          autocomplete="off"
                          :placeholder="$t('Pages.Content.Placeholders.Title')" />
                          <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="isCreateTitleError" uk-icon="icon: warning" ></span>
                          <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="isCreateTitleError" >{{$t('Pages.Content.TitleError')}}</span>
                      </div>
                  </div>
              </div>

            <label class="uk-form-label uk-margin">{{$t('Pages.Content.Labels.ChooseFiles') }} </label>
            <div class="uk-margin uk-margin-remove-bottom">
                  <div class="uk-form-controls">
                      <div class="uk-inline uk-width-1-1">
                        <label>
                          <input class="uk-checkbox uk-margin-right" type="checkbox" v-model="ar.hasMediaFiles" @change="handleHasMediaFileClick(ar.hasMediaFiles)" />{{ $t('Pages.Content.Labels.MediaFiles') }} <span class="uk-text-danger">*</span>
                          <button class="uk-button uk-button-primary uk-button-small uk-float-right" @click="openArMediaPicker()"  :disabled="!ar.hasMediaFiles">{{ $t('Actions.ChooseMediaList') }}</button>
                        </label>
                      </div>
                  </div>
                  <ul class="uk-list uk-list-disc" >
                    <li v-for="(item,index) in ar.mediaFileList" :key = "index">
                      <div class="uk-flex">
                        <span class="uk-text-truncate uk-flex-auto">  {{ item.title || item.name }}</span>
                        <span  uk-icon="icon: trash; ratio: .9" class="icon-control uk-float-right uk-flex-none" :uk-tooltip="$t('Actions.Delete')" @click.stop="deleteMediaFile(item)" />
                      </div>
                    </li>
                  </ul>

            </div>

             <div class="uk-margin uk-margin-remove-top">
                  <div class="uk-form-controls">
                      <div class="uk-inline uk-width-1-1">
                        <label>
                          <input class="uk-checkbox uk-margin-right" type="checkbox" v-model="ar.hasTargetImages"  @change="handleHasTargetImageClick(ar.hasTargetImages)" /> {{ $t('Pages.Content.Labels.TargetImages') }} 
                          <button class="uk-button uk-button-primary uk-button-small uk-float-right" @click="openArMediaPicker('IMAGE')"  :disabled="!ar.hasTargetImages">{{ $t('Actions.ChooseTargetImages') }}</button>

                        </label>
                        <div v-if="ar.hasTargetImages" class="uk-margin-left-small uk-text-italic uk-text-small uk-text-warning">Only JPEG and PNG supported.</div>
                      </div>
                  </div>
                  <ul class="uk-list uk-list-disc" >
                    <li v-for="(item,index) in ar.targetImageList" :key = "index">
                       <div class="uk-flex">
                        <span class="uk-text-truncate uk-flex-auto">  {{ item.title }}</span>
                        <span  uk-icon="icon: trash; ratio: .9" class="icon-control uk-float-right uk-flex-none" :uk-tooltip="$t('Actions.Delete')" @click.stop="deleteTargetImage(item)" />
                      </div>
                    </li>
                  </ul>
            </div>
          </form>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="resetArForm()">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!isLoading" class="uk-button uk-button-primary" :disabled="isCreateArDisabled" @click="createArContent()">{{ $t('Pages.Content.CreateArContent') }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>

  <div v-if="showArPicker" id="media-ar-picker-modal" container="#content" uk-modal="bg-close: false; esc-close: false; stack: true">
    <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
        <ModalMediaArPicker :media-class-filter="contentType" @selectArs="selectedArs( $event )"/>
    </div>
  </div>
  </div>

</template> 

<script>
import { mapState } from 'vuex';
import PublishingDashboard from './PublishingDashboard';
import BaseTable from '@/components/tables/BaseTable.vue';
import DraggableItem from '@/components/draggable/DraggableItem.vue';
import ImageOrAbbrevation from '@/components/images/ImageOrAbbrevation.vue';
import { isContent360Video, isContent2D, isContentCollection, EntertainmentCategoryList } from '@/utils/content';
import { ContentType, MediaClass } from '../../utils/enums';
import Notification from '@/components/util/Notification';
import ToggleButton from '@/components/buttons/ToggleButton.vue';
import ImgOrFallback from '@/components/images/ImageOrFallback';
import { Permissions } from '@/utils/enums';
import { isAdBlocker } from '@/utils/adBlocker';
import { SupportedLanguages } from '@/utils/content';

export default {
  name: 'ContentView',
  components: {
    PublishingDashboard, BaseTable, DraggableItem, ImageOrAbbrevation, ToggleButton, ImgOrFallback, 
    ModalMediaPicker: () => import('@/components/modals/ModalMediaPicker.vue'),
    ModalAdPicker: () => import('@/components/modals/ModalAdPicker.vue'),
    ModalInteractivePicker: () => import('@/components/modals/ModalInteractivePicker.vue'),
    ModalMediaArPicker:()=> import('@/components/modals/ArPicker.vue')
  },
  props: {
    collectionId: { type: String, default: '' },
    propertyId: { type: String, default: '' },
    id: { type: String, default: '' }
  },
  data() {
    return {
      loadData: true,
      isContentCollection,
      loadingMore: false,
      fetching: false,
      ContentType,
      contentFilter: this.$route?.query?.filter || '',
      deletingList: [],
      orgCollections: [],
      selectedInput: '',
      movingContent: null,
      copyingContent: null,
      selectedFolder: '',
      mutationRoles: [Permissions.ADMIN, Permissions.CONTENT_MANAGER],
      form: {
        name: '',
        type: 'SEASON',
        description: '',
        staging: false,
        nameError: '',
        keyArtMedia : ''
      },
      isLoading: false,
      isLangLoading: false,
      showPicker: false,
      editCollection: null,
      icon: '',
      contentType: '',
      showAdPicker: false,
      showInteractivePicker: false,
      interactive: {
        title: '', 
        headline: '',
        description: '',
        tags: [],
        category: 'Entertainment'
      },
      tag: '',
      story: '',
      EntertainmentCategoryList,
      visibility: '',
      saving: false,
      toggle: false,
      SupportedLanguages,
      langForm: {
        language: '',
        title: '',
        description: '',
        languageError: '',
        titleError: ''
      },
      nameI18n: [ { language: 'en-US', value: '' } ],
      descriptionI18n: [ { language: 'en-US', value: '' } ],
      collectionData: this.content,
      deletingLangList: [],
      editLanguage: null,
      orgId: this.id,
      currentCollectionId: '',
      langTableHeaders: [
      {
        name: this.$t('Languages.Language'),
        class: 'uk-width-medium'
      }, {
        name: this.$t('Generic.Labels.Title'), 
        class: 'uk-width-large'
      },
       {
        name: this.$t('Languages.Description'), 
        class: 'uk-width-2xlarge uk-preserve-width'
      },
      {
        name: this.$t('Actions.Actions'), 
        class: 'uk-width-small uk-text-center'
      }],
      orgCollectionsLoading: false,
      ar: {
        title:'',
        hasMediaFiles:false,
        mediaFileList:[],
        hasTargetImages:false,
        targetImageList:[]
      },
      showArPicker: false
    }
  },
  computed: {
    tableHeaders () {
      const headers = [
        {
          name: this.$t('Pages.Publishing.Headers.Type'),
          class: this.isAdmin ? '' : 'uk-width-small',
        }, {
          name: this.$t('Pages.Publishing.Headers.Name'), 
          class: ''
        }, {
          name: '', 
          class: this.isAdmin ? 'uk-width-xlarge uk-preserve-width': 'uk-width-2xlarge uk-preserve-width'
        }, {
          name: this.$t('Pages.Collections.Headers.Availability'),
          class:'uk-width-small uk-text-center'
        }, {
          name: this.$t('Pages.Collections.Headers.Status'),
          class: 'uk-width-small uk-text-center'
        }
      ]
      if (this.isMutable) {
        headers.unshift(
        {
          name: '',
          class: 'uk-width-auto',
        },{
          name: '', 
          class: '' 
        })
        headers.push(
          {
            name:  this.$t('Pages.Content.Availability.Visibility'),
            class: 'uk-width-small uk-text-center',
          },
           {
            name: this.$t('Pages.Collections.Staging'),
            class: 'uk-width-small uk-text-center',
          },
          {
            name: this.$t('Actions.Actions'), 
            class: 'uk-width-small uk-text-center auto@m auto@l' 
          }
        )
      }
      return headers
    },
    ...mapState({
      content: state => (state.venom.org.content ) ? state.venom.org.content : {},
      viewer: state => state.venom.org.viewer,
      featureSupport: state => state.venom.org.options?.featureSupport
    }),
    contentNodes() {
      const items = this.content.items;
      if ( !items ) return [];
      const { nodes } = items;
      if ( this.collectionId ) 
        return this.contentFilter ? nodes.filter( node => this.filterType( node ) === this.contentFilter ) : nodes;
      for( const node in nodes )
        node.checked = false;
      return nodes;
    },
    moveHeader () {
      return this.copyingContent ? this.$t('Actions.Copy') : this.$t('Pages.Content.MoveContent', { name: this.contentTitle(this.movingContent) });
    },
    searchResults() {
      const folderId = this.$route.params.collectionId
      // if (this.selectedInput.length === 0) return this.orgCollections;
      return this.orgCollections.filter(col => {
        if (col.value?.id === folderId) return false
        if (col.name === this.selectedInput)
          this.selectedFolder = col.value.id;
        return col.name.toLowerCase().match(this.selectedInput.toLowerCase());
      });
    },
    isDisabled( name ) {
      return !name || name.trim().length<3 || ( name && name.length<3 );
    },
    isCreateIVDisabled () {
      return !this.interactive.title || this.interactive.title.trim().length<3 || ( this.interactive.title && this.interactive.title.length<3 );
    },
    isCreateTitleError (){
       return  this.ar.title.length > 0 && (!this.ar.title || this.ar.title.trim().length<3 || ( this.ar.title && this.ar.title.length<3 ))
    },
    isCreateArDisabled () {
      return !this.ar.title || this.ar.title.trim().length<3 || ( this.ar.title && this.ar.title.length<3 ) || this.ar.mediaFileList.length === 0
    },
    folderName () {
      return this.isFetchig ? 'Laoding..' : this.content.i18nName ? this.content.i18nName.localized.value : '';
    },
    buttonText () {
      switch ( this.contentType ) {
        case 'video':
          return this.$t('Pages.Advertising.Labels.Continue');
        case 'stream':
          return this.$t('Pages.Advertising.Labels.Continue');
        case 'ad':
          return this.$t('Pages.Advertising.Labels.Continue');
        case 'interactive':
          return this.$t('Pages.Advertising.Labels.Continue');
        default:
          return this.$t('Actions.Select');
        }
    },
    mediaFilter () {
      switch ( this.contentType ) {
        case 'video':
          return MediaClass.VIDEO;
        case 'stream':
          return MediaClass.LIVE;
        default:
          return MediaClass.IMAGE;
        }
    },
    contentMutation () {
      switch ( this.contentType ) {
        case 'video':
          return 'createVod';
        case 'stream':
          return 'createStream';
        case 'ad':
          return 'createAdContent';
        case 'interactive':
          return 'createInteractiveContent';
        default:
          return '';
        }
    },
    propertyMap () {
      const prop = {} 
      this.viewer?.permissions?.forEach(perm => {
        prop[perm.property.id] = this.isAdmin ? Permissions.ADMIN : perm.type
      })
      return prop
    },
    isAdmin () {
      return this.viewer?.role === Permissions.ADMIN  ? true : false
    },
    isMutable () {
      return this.isAdmin || this.mutationRoles.includes(this.propertyMap[this.propertyId])
    },
    collectionLanguages () {
      const languages = [];
      let language = {};
      if (this.collectionData?.i18nName?.all?.length) {
        for ( const title of this.collectionData?.i18nName?.all ) {
            language.language = title.language;
            language.name = title.value;
            const description = this.collectionData?.i18nDescription?.all.find( l => l.language === title.language );
            language.description = description?.value;
            languages.push( language );
            language = {};
        }
      }
      const sortedList = [...languages]?.sort((a, b) => this.langTitle( a ).toString().localeCompare(this.langTitle( b ).toString().toString())) || [];
      return sortedList.sort( (x,y)=> x.language === 'en-US' ? -1 : y.language === 'en-US' ? 1 : 0 );
    },
    availableLanguages () {
      const langArray = this.collectionLanguages?.map( lang => lang.language );
      return SupportedLanguages?.filter( lang => !langArray.includes( lang ) ) || [];
    }
  },
  beforeDestroy () {
    this.loadData = false
    const modalElement = document.getElementById('move-modal')
    if (modalElement) {
      window.UIkit.modal('#move-modal').$destroy(true);
    }
  },
  methods: {

    handleHasMediaFileClick(value){
      if(!value){
        this.ar.mediaFileList = [];
      }
    },
    handleHasTargetImageClick(value){
      if(!value){
        this.ar.targetImageList = [];
      }
    },
    navigateBack(){
      let ids = this.content.parents;
      let propertyId = ids[0].id;
      let propertyName = ids[0].name;

      let url = `/organizations/${this.orgId}/publishing/${propertyId}/${propertyName}/content`;
      this.$router.push(url);

    },
    getContentItemArtKey (contentItem) {
      return contentItem.type === 'AD' ? (contentItem.art?.key?.url || contentItem.ref.ad?.banner?.url || '' ): (contentItem.art?.key?.url || '');
    },
    async fetchCollectionContent() {
      this.fetching = true;
      const response = await this.$store.dispatch('fetchContent', { collectionId: this.collectionId, after: this.content.items?.pageInfo.endCursor });
      if ( response ) {
        this.fetching = false
        this.currentCollectionId = this.collectionId
        if( response && this.loadData && response.items?.pageInfo?.hasNextPage )
          this.fetchCollectionContent();
      }
    },
    getAccessColor ({ access }) {
      let indicator = 'none';
      if ( access === 'PUBLIC' ) {
        indicator = 'green';
      } else if ( access === 'PRIVATE' ) {
        indicator = 'red';
      } else if ( access === 'TESTING' ) {
        indicator = 'orange';
      }
      return indicator;
    },
    getContentIcon ( type ) {
      switch ( type ) {
        case ContentType.VOD:
          return 'icon: camera; ratio: 1.4';
        case ContentType.STREAM:
          return 'icon: video-camera; ratio: 1.8';
        case ContentType.INTERACTIVE:
          return 'icon: play-circle; ratio: 1.4';
        case ContentType.AD:
          return 'icon: tv; ratio: 1.4';
        default:
          return 'icon: album; ratio: 1.4';
        }
    },
     getContentType ( type ) {
      switch ( type ) {
        case ContentType.VOD:
          return this.$t('Pages.Content.ContentTypes.Video');
        case ContentType.STREAM:
          return this.$t('Pages.Content.ContentTypes.LiveEvent');
        case ContentType.INTERACTIVE:
          return this.$t('Pages.Content.ContentTypes.Interactive');
        case ContentType.AD:
          return this.$t('Pages.Content.ContentTypes.Ad');
        case ContentType.AR:
          return this.$t('Pages.Content.ContentTypes.AR');
        default:
          return this.$t('Pages.Content.ContentTypes.Folder');
        }
    },
    toContent ( content ) {
      return content.type === ContentType.COLLECTION
          ? { name: 'Content', params: { id: this.orgId, propertyId: this.propertyId, collectionId: content.id } }: { name: 'ContentDetails', params: { id: this.orgId, propertyId: this.propertyId, contentId: content.id }, query: { filter: this.contentFilter }};
    },
    checkAppRestrictions ( content ) {
      return content.geoRestrictions && content.appRestrictions.allowOnly.length > 0;
    },
    checkGeoRestrictions ( content ) {
      return content.geoRestrictions && content.geoRestrictions.allowOnly.length > 0;
    },
    checkAvailabilityRestrictions ( content ) {
      return content.availability && ( content.availability.start ||  content.availability.end );
    },
    async drop ( draggableContent, contentId ) {
      const { id, direction } = draggableContent;
      if ( id !== contentId ) {
        this.fetching = true;
        await this.$store.dispatch('setContentPosition', { variables: this.queryVariables( direction, id, contentId ), collectionId: this.collectionId });
        this.fetching = false;
      }
    },
    queryVariables ( direction, draggableId, contentId ) {
      return direction === 'upwards' ? { input : { content: draggableId, before: contentId } } : { input : { content: draggableId, after: contentId } };
    },
    contentTitle ( contentItem ) {
      if ( contentItem === null )
        return '';
      return contentItem.primaryLanguage ? contentItem.primaryLanguage.title : contentItem.ref.title?contentItem.ref.title:'';
    },
    filterType ( contentItem ) {
      if ( /COLLECTION|INTERACTIVE|AD|STREAM|AR/.test( contentItem.type ) ) return contentItem.type;
      if ( isContent360Video( contentItem ) ) return '360Video';
      if ( isContent2D( contentItem ) ) return '2D';
      return undefined;
    },
    setFilter(filter) {
      this.contentFilter = filter;
      const url = new URL(window.location);
      url.searchParams.set('filter', this.contentFilter);
      window.history.replaceState({}, '', url);
    },
    deleteContentRef ( content ) {
      this.deletingList.forEach( i=> {
        if( i.id == content.id )
          this.deletingList.splice(i,1);
      });
    },
    async deleteContent( content ) {
      if ( isContentCollection(content) && ( content.ref.items.totalCount > 0 || content.ref.folders.totalCount > 0 ) ) {
        window.UIkit.modal('#info-content-modal').show();
      } else {
        const UIkit = window.UIkit;
        UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDelete', { name: this.contentTitle(content) } ), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
          this.deletingList.push( content );
          const response = isContentCollection(content) ? await this.$store.dispatch( 'deleteCollection', { collectionId: content.id, collection: this.collectionId } )
                            : await this.$store.dispatch( 'deleteContent', { contentId: content.id, collectionId: this.collectionId } );
          if ( response ) {
            this.deleteContentRef( content );
            Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: this.contentTitle ( content ) }));
          } else {
            Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), response?.errors[0]?.message, 'error');
          }
        },()=>{});
      }
    },
    clickSelect ( folder ) {
      window.UIkit.dropdown('#select').hide();
      this.selectedInput = folder.name;
      this.selectedFolder = folder.value.id;
    },
    moveContent ( content ) {
      this.movingContent = content;
      this.getOrgCollections();
    },
    copyContent ( content ) {
      this.copyingContent = content;
      this.getOrgCollections();
    },
    async getOrgCollections () {
      this.orgCollectionsLoading = true;
      const collections = await this.$store.dispatch('fetchOrgCollections', { orgId: this.orgId });
      collections.slice(0, this.collectionId).concat(collections.slice(this.collectionId + 1, collections.length));
      const namedCollections = ( collections || []).map( collection => {
        const parentNames = collection.parents.filter( parent => /Property|Collection/.test( parent.__typename ) ).map( parent => parent.name ).reverse().join( ' / ' );
          return { value: collection, name: `${parentNames} / ${collection.i18nName?.localized?.value || 'Unknown'}` };
        });
       this.orgCollections = namedCollections.sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
       this.orgCollectionsLoading = false;

    },
    async moveOrCopyContent() {
      window.UIkit.modal('#move-modal').hide();
      const response = this.movingContent ? await this.$store.dispatch('updateContent', { contentId: this.movingContent.id, collectionId: this.selectedFolder, collection: this.collectionId }) :
                       await this.$store.dispatch('copyContentDeep', { contentId: this.copyingContent.id, collection: this.selectedFolder });
      if ( response.data !== null ) {
        this.movingContent ? Notification.showNotification(this.$t( 'MoveModal.Moved'), this.$t( 'MoveModal.HasBeenMoved', { name: this.contentTitle ( this.movingContent ) }))
                           : Notification.showNotification(this.$t( 'MoveModal.Copied'), this.$t( 'MoveModal.HasBeenCopied', { name: this.contentTitle ( this.copyingContent ) }));
      
      } else {
        this.movingContent ? Notification.showNotification(this.$t( 'MoveModal.NotMoved'), (response?.errors[0]?.message) || response, 'error')
        : Notification.showNotification(this.$t( 'MoveModal.NotCopied'), (response?.errors[0]?.message) || response, 'error');
      }
      this.movingContent = null;
      this.copyingContent = null;
      this.selectedInput = '';
      this.selectedFolder = '';
    },
    cancel() {
      this.movingContent = null;
      this.copyingContent = null;
      this.selectedInput = '';
      this.selectedFolder = '';
      window.UIkit.modal('#move-modal').hide();
    },
    async setStaging( collection, staging ) {
      await this.$store.dispatch('updateCollection', { variables: { staging, collection: collection.id }, collectionId: this.collectionId });
    },
    openDropdown () {
      window.UIkit.dropdown('#select').show();
      if (!this.selectedInput) document.getElementById('select').scrollTop = 0
    },
    validateForm () {
      let isValid = true
      this.validateName(this.form.name)
      if (this.form.nameError ) {
        isValid = false
      } else if (this.editCollection && !this.form.name) {
        this.nameError = this.$t('Generic.Errors.Min3Chars')
      } else if (!this.editCollection ) {
        if (this.form.nameError ) {
          isValid = false
        } else if (!this.form.name) {
          this.form.nameError = !this.form.name ? this.$t('Generic.Errors.Min3Chars') : false
          isValid = false
        }
      }
      return isValid
    },
    async onSubmit() {
      try {
        if (!this.validateForm()) return false
        this.isLoading = true;
        const newCollection = this.editCollection ? await this.$store.dispatch("updateCollection", {
          variables: {
            i18nName: [ { language: 'en-US', value: this.form.name } ],
            i18nDescription: [ { language: 'en-US', value: this.form.description } ],
            keyArt: this.form.keyArtMedia ? { id: this.form.keyArtMedia.id } : null,
            type: this.form.type,
            staging: this.form.staging,
            collection: this.editCollection.id
          }, collectionId: this.collectionId
        }) : this.$store.dispatch("createCollection", {
          name: this.form.name,
          description: this.form.description,
          keyArt: this.form.keyArtMedia ? { id: this.form.keyArtMedia.id } : null,
          type: this.form.type,
          staging: this.form.staging,
          collection: this.collectionId
        });
        window.UIkit.modal('#new-content-collection-modal').hide();
        if( newCollection && !this.editCollection )
          Notification.showNotification(this.$t('Actions.Created'), this.$t('Pages.MediaLibrary.FolderCreated', { name: this.form.name }));
        else
          Notification.showNotification(this.$t('Actions.Updated'), this.$t('Pages.MediaLibrary.FolderUpdated'));
        this.isLoading = false;
        this.resetForm();
      } catch (err) {
        this.resetForm();
      }
    },
    validateName( name ) {
      if (this.form.nameError) {
        this.form.nameError = name && name.length >=3 ? false : this.$t('Generic.Errors.Min3Chars');
      } else {
          this.form.nameError = name && name.length<3 || !name.trim() ? this.$t('Generic.Errors.Min3Chars') : false;
        }
    },
    validateTitle( name ) {
      if (this.langForm.titleError) {
        this.langForm.titleError = name.length >=3 ? false : this.$t('Generic.Errors.Min3Chars');
      } else {
        if ( name ) {
          this.langForm.titleError = name.length<3 || !name.trim() ? this.$t('Generic.Errors.Min3Chars') : false;
        }
      }
    },
    checkIfEnter ( event ) {
      if( event.keyCode === 13 ) {
        if(this.interactive.tags.indexOf(this.tag) === -1)
          this.interactive.tags.push(this.tag);
        this.tag = '';
      }
    },
    deleteTag (tag) {
      var index = this.interactive.tags.indexOf(tag);
      this.interactive.tags.splice(index,1);
    },
    closeModal () {
      window.UIkit.modal('#media-picker-modal').hide();
      if ( !this.contentType )
        window.UIkit.modal('#new-content-collection-modal').show();
      setTimeout( function() {
        this.showPicker = false;
      }, 10 );
      this.contentType = '';
    },
    closeInteractiveModal () {
      window.UIkit.modal('#media-interactive-picker-modal').hide();
    },
    async selectedMedia ( media ) {
      if ( this.contentType && media  ) {
        const newContent = await this.$store.dispatch( this.contentMutation, { collection: this.collectionId, bundle: media.id });
        let contentId;
        if ( newContent.createVodFromMediaBundle )
          contentId = newContent.createVodFromMediaBundle.vod.id;
        else if ( newContent.createStreamFromMediaBundle )
          contentId = newContent.createStreamFromMediaBundle.stream.id;
        window.UIkit.modal('#media-picker-modal').$destroy(true);
        this.contentType = '';
        this.$router.push({ name: 'ContentDetails', params: { id: this.orgId, propertyId: this.propertyId, contentId: contentId }, query: { filter: this.contentFilter }});
      } else
        this.form.keyArtMedia = media;
    },
    openMediaPicker ( type ) {
      if ( type ) {
        this.contentType = type;
        window.UIkit.dropdown('#new-content-types').hide();
      }
      this.showPicker = true;
      setTimeout( () => {
        window.UIkit.modal('#media-picker-modal').show();
      }, 100 );
    },
    async openAdPicker () {
      this.contentType = 'ad';
      if( await isAdBlocker() )
        Notification.showNotification( this.$t( 'Pages.Advertising.Notifications.AdBlockerNotice' ), this.$t( 'Pages.Advertising.Notifications.AdBlockerMessage' ), 'error' );
      window.UIkit.dropdown('#new-content-types').hide();
      this.showAdPicker = true;
      setTimeout( () => {
        window.UIkit.modal('#media-ad-picker-modal').show();
      }, 100 );
    },
    openInteractivePicker () {
      this.contentType = 'interactive';
      window.UIkit.dropdown('#new-content-types').hide();
      this.showInteractivePicker = true;
      setTimeout( () => {
        window.UIkit.modal('#media-interactive-picker-modal').show();
      }, 100 );
    },
    openNewArModal () {
      this.contentType = 'ar';
      window.UIkit.dropdown('#new-content-types').hide();
      // this.showInteractivePicker = true;
      setTimeout( () => {
        window.UIkit.modal('#new-ar-modal').show();
      }, 100 );
    },
  
    openArMediaPicker ( type) {
      this.contentType = type;
      this.showArPicker = true;
      setTimeout( () => {
        window.UIkit.modal('#media-ar-picker-modal').show();
      }, 100 );
    },
    resetForm () {
      this.form.name = '';
      this.form.description = '';
      this.form.keyArtMedia = '';
      this.form.type = 'SEASON';
      this.form.staging = false;
      this.form.nameError = '';
      this.editCollection = null;
      this.icon = '';
    },
    resetIVForm () {
      this.interactive = {
        title: '',
        headline: '',
        description: '',
        tags: [],
        category: 'Entertainment'
      }
      this.tag = '';
      window.UIkit.modal('#media-interactive-picker-modal').show();
    },
    resetArForm () {
      this.ar = {
        title: '',
      }
      this.tag = '';
      // window.UIkit.modal('#media-interactive-picker-modal').show();
    },
    clickEdit ( collection ) {
      this.editCollection = collection;
      window.UIkit.modal('#new-content-collection-modal').show();
      this.form.name = this.contentTitle(collection);
      this.form.type = collection.ref.seasonType;
      this.form.staging = collection.ref.staging;
      this.form.description = collection.ref.i18nDescription?.localized?.value || '';
      this.icon = collection.ref.icon ? collection.ref.icon.url : '';
    },
    async selectedAd ( ad ) {
      if ( ad ) {
        const newContentAd = await this.$store.dispatch( this.contentMutation, { collection: this.collectionId, ads: [ ad.id ] });
        if ( newContentAd ) {
          setTimeout( () => {
            window.UIkit.modal('#media-ad-picker-modal').$destroy(true);
            this.$router.push({ name: 'ContentDetails', params: { id: this.orgId, propertyId: this.propertyId, contentId: newContentAd.createAdContent.ads[0].id }, query: { filter: this.contentFilter }});
          }, 500)
        }
      }
    },
    async selectedInteractive ( iv ) {
      this.story = iv;
      window.UIkit.modal('#media-interactive-picker-modal').hide();
      window.UIkit.modal('#new-interactive-content-details-modal').show();
    },
    async createIVContent () {
      const content = await this.$store.dispatch( 'createInteractiveContent', { collection: this.collectionId, story: this.story.id, interactive: this.interactive });
        if ( content ) {
          window.UIkit.modal('#media-interactive-picker-modal').$destroy(true);
          this.$router.push({ name: 'ContentDetails', params: { id: this.orgId, propertyId: this.propertyId, contentId: content.id }, query: { filter: this.contentFilter }});
        }
    },
    async selectedArs ( arArr ) {
      if(this.contentType=="IMAGE"){
        this.ar.targetImageList = arArr;
      }else{
      this.ar.mediaFileList = arArr;
      }
      window.UIkit.modal('#media-ar-picker-modal').hide();
      // window.UIkit.modal('#new-interactive-content-details-modal').show();
    },
    async createArContent () {
      //do some network calls here
      let mediaFileListIds = this.ar.mediaFileList.map((item)=>item.id);
      let targetImageListIds = this.ar.targetImageList.map(item=>item.id);
      this.isLoading = true;
      const content = await this.$store.dispatch( 'createAr', { collection: this.collectionId, title:this.ar.title,mediaList:mediaFileListIds,targetImages:targetImageListIds });
        if ( content ) {
          window.UIkit.modal('#new-ar-modal').$destroy(true);
          this.$router.push({ name: 'ContentDetails', params: { id: this.orgId, propertyId: this.propertyId, contentId: content.ar.id }, query: { filter: this.contentFilter }});
        }
    },
    closeAdModal () {
      window.UIkit.modal('#media-ad-picker-modal').hide();
      setTimeout( function() {
        this.showAdPicker = false;
      }, 10 );
      this.contentType = '';
    },
    async setVisibility () {
      const selectedItems = this.contentNodes.filter( node => node.checked === true );
      this.saving = true;
      for( const item of selectedItems ) {
        await this.$store.dispatch('updateContent', {
          input: {
            content: item.id,
            access: this.visibility,
          },
          collectionId: this.collectionId
        });
      }
      this.saving = false;
      await this.$store.dispatch('fetchContent', { collectionId: this.collectionId, after: null }); 
      this.fetchCollectionContent();
      this.visibility = '';
      window.UIkit.modal('#content-visibility-modal').hide();
    },
    cancelSetVisibility () {
      this.visibility = '';
    },
    deleteLangRef ( lang ) {
      this.deletingLangList.forEach( l=> {
        if( l.language== lang.language )
          this.deletingLangList.splice(l,1);
      });
    },
    resetLangForm () {
      this.langForm.language = '';
      this.langForm.title = '';
      this.langForm.description = '';
      this.langForm.titleError = '';
      this.langForm.languageError = '';
      this.editLanguage= null;    
    },
     clickEditLang ( lang ) {
      this.editLanguage = lang;
      window.UIkit.modal('#content-lang-modal').show();
      this.langForm.title = lang.name;
      this.langForm.description = lang.description;
      this.langForm.language = lang.language;
    },
    langTitle( lang ) {
      return this.$t( `Languages.isoLanguageLabels.${lang.language}` )
    },
    async onLangCreate () {
      let isValid = true;

      if (!this.langForm.title || this.langForm.title.trim().length<3 || ( this.langForm.title && this.langForm.title.length<3 ) ) {
        this.langForm.titleError = this.$t('Generic.Errors.Min3Chars');
        isValid = false;
      }
      if (!this.langForm.language) {
        this.langForm.languageError = this.$t('Languages.LanguageError');
        isValid = false;
      }
      if( isValid ) {
      try {
        this.isLangLoading = true;

        const collection = await this.$store.dispatch("updateCollection", {
          variables: {
            i18nName: [ { language: this.langForm.language, value: this.langForm.title } ],
            i18nDescription: [ { language: this.langForm.language, value: this.langForm.description } ],
            collection: this.collectionId
          }, collectionId: this.collectionId
        })
        window.UIkit.modal('#content-lang-modal').hide();

        if ( collection && !this.editLanguage )
          Notification.showNotification(this.$t( 'Generic.Messages.Successful'), this.$t( 'Languages.Notification.NewLanguage'));
        else
          Notification.showNotification(this.$t( 'Generic.Messages.Successful'), this.$t( 'Languages.Notification.UpdateLanguage'));
          this.isLangLoading = false;

        this.resetLangForm();
      } catch (err) {
        this.resetLangForm();
      }
      } else return isValid;
    },
    async deleteLanguage ( language ) {
      if ( language.language === 'en-US' ) {
        window.UIkit.modal('#info-language-modal').show();
      } else {
      window.UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDelete', { name: this.langTitle( language ) }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
          this.deletingLangList.push( language );
          const response = await this.$store.dispatch( 'updateCollection',  { variables: {
            i18nName: { language: language.language, delete: true },
            i18nDescription: { language: language.language, delete: true },
            collection: this.collectionId
          }, collectionId: this.collectionId });
          if ( response ) {
            Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: this.langTitle( language ) }));
          } else {
            Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), this.$t( 'DeleteModal.CouldNotRemove', { name: this.langTitle( language ) }), 'error');
          }
        },()=>{});
        }
    },
    deleteMediaFile ( item){
      this.ar.mediaFileList = this.ar.mediaFileList.filter((element)=>element.id !== item.id);
    },
    deleteTargetImage ( item){
      this.ar.targetImageList = this.ar.targetImageList.filter((element)=>element.id !== item.id);
    }
  },
  mounted () {
    window.UIkit.util.on(document, 'show', '#select', async () => {
      if (!this.selectedInput) {
        document.getElementById('select').scrollTop = 0
      }
    });
  },
  watch: {
    collectionId: {
      handler ( newVal ) {
        if ( newVal ) {
          this.$store.commit('setContent', { items: [] });
          this.fetchCollectionContent();
        }
      },
      deep: true,
      immediate: true
      },
      content: {
        handler ( newVal ) {
          if ( newVal ) {
            this.collectionData = newVal;
          }
        },
        deep: true,
        immediate: true
      }
    }
}
</script>

<style lang="scss" scoped>
.icon-image {
  width: 85px;
  height: 48px;
}
.icon-control {
  height: 20px; 
  width: 20px;
  cursor: pointer;
}
.icon-drag {
  height: 20px; 
  width: 20px;
  cursor: move;
}
.uk-badge {
  height: 11px; 
  min-width: 11px;
}
.icon-image,.name,.type,.access,.icon,.back-link, .edit-icon {
  cursor: pointer;
}
.controls, .content-title {
  color: var(--app-primary-color);
  word-break: break-all;
}
.triangle-down {
 position: absolute;
 margin: 10px;
 right: 0;
}
.new-collection-btn {
  padding: 0 20px;
}
.uk-dropdown {
  min-width: 150px;
}
.close:hover {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
.close {
  vertical-align: middle;
  display: flex;
  color: #a8a8a8;
  cursor: pointer;
}
.selected {
  background-color: var(--app-primary-color);
}
.uk-table-divider>:first-child>tr:not(:first-child), .uk-table-divider>:not(:first-child)>tr, .uk-table-divider>tr:not(:first-child) {
  border-bottom: 1px solid var(--border-color);
}
.trash-icon {
  height: 20px; 
  width: 20px;
  cursor: pointer;
}

.uk-text-truncate {
  color:var(--app-primary-color);
  cursor:pointer;
}
.uk-text-truncate:hover {
  color:var(--text-color);
}
</style>
